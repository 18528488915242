import React from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2' 
import withReactContent from 'sweetalert2-react-content'

const PopSwal = withReactContent(Swal)

function Loading() {
  return <div>Loading...</div>;
}


export function showLoading(msg){
  PopSwal.fire({ 
    title: msg,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      PopSwal.showLoading();
    }
  });
}

export function loadSuccess(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'success',
    timer: 1000,
    showConfirmButton: false
  });
}

export function loadError(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'error',
    timer: 2000,
    showConfirmButton: true
  })
}

export function destroySession(){
  Cookies.remove("obulus",{ path: '/' });
  Cookies.remove("islogged",{ path: '/' });
  Cookies.remove("argos",{ path: '/' });
}


export const APIHOST = "https://gsk-api.roadshowcampaign.com/api/";
