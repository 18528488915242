import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/styles.css"
import { Images } from "./Images";
import { useState } from "react";
import axios from "axios";
import { showLoading, loadError , APIHOST} from "./gaia";
import Swal from 'sweetalert2' 
import withReactContent from 'sweetalert2-react-content'

const PopSwal = withReactContent(Swal)


function App() {
  const [name , SetName] = useState("");
  const [email , SetEmail] = useState("");
  const [isSubmitted, SetIsSubmitted] = useState(false);

  
  const submitForm = (evt) =>{
    evt.preventDefault();
    showLoading("Submitting...Please wait...")
    axios.post(`${APIHOST}register`, {
      name : name,
      email : email,
    },{}).then(function(res){
        let {data} = res;
        SetIsSubmitted(true);
        PopSwal.close()
    }).catch(function(er){
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
          window.location.href = "/login"
        }
        
      }else{
        loadError(er.message)
      }
    })
  } 

  return (
    <div className="App">
      <img className="logo" src={Images.logo} />
      {!isSubmitted &&<div className="rsvpblock">
        <form onSubmit={submitForm}>
          <img className="rsvpheree" src={Images.rsvp} />
          <div className="input-gold"><input placeholder="NAME"  onChange={(e)=>SetName(e.target.value)} required/></div>
          <div className="input-pink"><input placeholder="EMAIL" onChange={(e)=>SetEmail(e.target.value)} required/></div>
          <button type="submit" className="submit">SUBMIT</button>
        </form>
      </div>}
      {isSubmitted &&<div className="submitblock">
        <img className="thanks" src={Images.thanksconfirm} />
        <img className="seeyou" src={Images.seeyou} />
      </div>}
    </div>
  );
}

export default App;
